@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.alert-box-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}
@supports (-webkit-backdrop-filter: blur(10px)) {
  .alert-box-wrapper {
    -webkit-backdrop-filter: blur(10px);
  }
}
.alert-box-wrapper[aria-hidden=false] {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.alert-box-wrapper[aria-hidden=false] .alert-box {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.alert-box-wrapper[aria-hidden=true] {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.alert-box-wrapper[aria-hidden=true] .alert-box {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.alert-box-wrapper .alert-box {
  background: white;
  padding: 1.5rem;
  border-radius: 0.25rem;
  min-width: 18rem;
  max-width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 22px 0;
  margin-bottom: 20%;
  position: relative;
}
.alert-box-wrapper .alert-box > .alert-box-icon {
  position: absolute;
  top: -2.5em;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
}
.alert-box-wrapper .alert-box > .alert-box-icon + p {
  text-align: center;
  font-weight: bold;
  margin-top: 3em;
}
.alert-box-wrapper .alert-box > .alert-box-icon + p + .action {
  display: flex;
}
.alert-box-wrapper .alert-box > .alert-box-icon + p + .action button {
  flex-grow: 1;
  flex-basis: 50%;
}
.alert-box-wrapper .alert-box > .alert-box-icon + p + .action button:not(:last-child) {
  margin-right: 0.5em;
}
.alert-box-wrapper .alert-box.info .action .btn-confirm {
  border: 0;
  outline: none;
  color: #fff;
  background-color: #27c2ee;
}
.alert-box-wrapper .alert-box.info .action .btn-confirm:hover {
  background-color: #66d7f6;
}
.alert-box-wrapper .alert-box.error .action .btn-confirm {
  border: 0;
  outline: none;
  color: #fff;
  background-color: #f25d5c;
}
.alert-box-wrapper .alert-box.error .action .btn-confirm:hover {
  background-color: rgba(255, 105, 96, 0.8);
}
.alert-box-wrapper .alert-box.success .action .btn-confirm {
  border: 0;
  outline: none;
  color: #fff;
  background-color: #48dc51;
}
.alert-box-wrapper .alert-box.success .action .btn-confirm:hover {
  background-color: rgba(127, 220, 107, 0.8);
}
.alert-box-wrapper .alert-box.warning .action .btn-confirm {
  border: 0;
  outline: none;
  color: #fff;
  background-color: #f8ae64;
}
.alert-box-wrapper .alert-box.warning .action .btn-confirm:hover {
  background-color: #facd95;
}
.alert-box-wrapper .alert-box.question .action .btn-confirm {
  border: 0;
  outline: none;
  color: #fff;
  background-color: #87adbd;
}
.alert-box-wrapper .alert-box.question .action .btn-confirm:hover {
  background-color: #c9dae1;
}
.alert-box-wrapper h1 {
  font-size: 1.5rem;
  margin: 0;
}
.alert-box-wrapper p {
  font-size: 1rem;
  margin: 0;
}
.alert-box-wrapper .action {
  margin-top: 1rem;
  text-align: right;
}
.alert-box-wrapper button {
  background: #ddd;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  border: 1px solid #c4c4c4;
  padding: 0.5rem 1rem;
  outline: none;
}
.alert-box-wrapper button:hover {
  background-color: #f7f7f7;
}
.alert-box-wrapper button:active {
  background-color: #c4c4c4;
}
.alert-box-wrapper button:not(:first-child) {
  margin-left: 0.5rem;
}

@-webkit-keyframes alert-box-show {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes alert-box-show {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes alert-box-hide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes alert-box-hide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes alert-box-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.875em;
    width: 1.5625em;
  }
}
@keyframes alert-box-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.875em;
    width: 1.5625em;
  }
}
@-webkit-keyframes alert-box-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes alert-box-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@-webkit-keyframes alert-box-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes alert-box-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@-webkit-keyframes alert-box-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes alert-box-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes alert-box-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes alert-box-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
body.alert-box-toast-shown .alert-box-container {
  position: fixed;
  background-color: transparent;
}

body.alert-box-toast-shown .alert-box-container.alert-box-shown {
  background-color: transparent;
}

body.alert-box-toast-shown .alert-box-container.alert-box-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

body.alert-box-toast-shown .alert-box-container.alert-box-top-end,
body.alert-box-toast-shown .alert-box-container.alert-box-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

body.alert-box-toast-shown .alert-box-container.alert-box-top-left,
body.alert-box-toast-shown .alert-box-container.alert-box-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

body.alert-box-toast-shown .alert-box-container.alert-box-center-left,
body.alert-box-toast-shown .alert-box-container.alert-box-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

body.alert-box-toast-shown .alert-box-container.alert-box-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

body.alert-box-toast-shown .alert-box-container.alert-box-center-end,
body.alert-box-toast-shown .alert-box-container.alert-box-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

body.alert-box-toast-shown .alert-box-container.alert-box-bottom-left,
body.alert-box-toast-shown .alert-box-container.alert-box-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

body.alert-box-toast-shown .alert-box-container.alert-box-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

body.alert-box-toast-shown .alert-box-container.alert-box-bottom-end,
body.alert-box-toast-shown .alert-box-container.alert-box-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

body.alert-box-toast-column .alert-box-toast {
  flex-direction: column;
  align-items: stretch;
}

body.alert-box-toast-column .alert-box-toast .alert-box-actions {
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: 0.3125em;
}

body.alert-box-toast-column .alert-box-toast .alert-box-loading {
  justify-content: center;
}

body.alert-box-toast-column .alert-box-toast .alert-box-input {
  height: 2em;
  margin: 0.3125em auto;
  font-size: 1em;
}

body.alert-box-toast-column .alert-box-toast .alert-box-validation-message {
  font-size: 1em;
}

.alert-box-popup.alert-box-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.625em;
  box-shadow: 0 0 0.625em #d9d9d9;
  overflow-y: hidden;
}

.alert-box-popup.alert-box-toast .alert-box-header {
  flex-direction: row;
}

.alert-box-popup.alert-box-toast .alert-box-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 0.6em;
  font-size: 1em;
}

.alert-box-popup.alert-box-toast .alert-box-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}

.alert-box-popup.alert-box-toast .alert-box-close {
  position: initial;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}

.alert-box-popup.alert-box-toast .alert-box-content {
  justify-content: flex-start;
  font-size: 1em;
}

.alert-box-popup.alert-box-toast .alert-box-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}

.alert-box-popup.alert-box-toast .alert-box-icon-text {
  font-size: 2em;
  font-weight: 700;
  line-height: 1em;
}

.alert-box-popup.alert-box-toast .alert-box-icon.alert-box-success .alert-box-success-ring {
  width: 2em;
  height: 2em;
}

.alert-box-popup.alert-box-toast .alert-box-icon.alert-box-error [class^=alert-box-x-mark-line] {
  top: 0.875em;
  width: 1.375em;
}

.alert-box-popup.alert-box-toast .alert-box-icon.alert-box-error [class^=alert-box-x-mark-line][class$=left] {
  left: 0.3125em;
}

.alert-box-popup.alert-box-toast .alert-box-icon.alert-box-error [class^=alert-box-x-mark-line][class$=right] {
  right: 0.3125em;
}

.alert-box-popup.alert-box-toast .alert-box-actions {
  height: auto;
  margin: 0 0.3125em;
}

.alert-box-popup.alert-box-toast .alert-box-styled {
  margin: 0 0.3125em;
  padding: 0.3125em 0.625em;
  font-size: 1em;
}

.alert-box-popup.alert-box-toast .alert-box-styled:focus {
  box-shadow: 0 0 0 0.0625em #fff, 0 0 0 0.125em rgba(50, 100, 150, 0.4);
}

.alert-box-popup.alert-box-toast .alert-box-success {
  border-color: #a5dc86;
}

.alert-box-popup.alert-box-toast .alert-box-success [class^=alert-box-success-circular-line] {
  position: absolute;
  width: 2em;
  height: 2.8125em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}

.alert-box-popup.alert-box-toast .alert-box-success [class^=alert-box-success-circular-line][class$=left] {
  top: -0.25em;
  left: -0.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 2em 2em;
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}

.alert-box-popup.alert-box-toast .alert-box-success [class^=alert-box-success-circular-line][class$=right] {
  top: -0.25em;
  left: 0.9375em;
  -webkit-transform-origin: 0 2em;
  transform-origin: 0 2em;
  border-radius: 0 4em 4em 0;
}

.alert-box-popup.alert-box-toast .alert-box-success .alert-box-success-ring {
  width: 2em;
  height: 2em;
}

.alert-box-popup.alert-box-toast .alert-box-success .alert-box-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}

.alert-box-popup.alert-box-toast .alert-box-success [class^=alert-box-success-line] {
  height: 0.3125em;
}

.alert-box-popup.alert-box-toast .alert-box-success [class^=alert-box-success-line][class$=tip] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}

.alert-box-popup.alert-box-toast .alert-box-success [class^=alert-box-success-line][class$=long] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}

.alert-box-popup.alert-box-toast.alert-box-show {
  -webkit-animation: showSweetToast 0.5s;
  animation: showSweetToast 0.5s;
}

.alert-box-popup.alert-box-toast.alert-box-hide {
  -webkit-animation: hideSweetToast 0.2s forwards;
  animation: hideSweetToast 0.2s forwards;
}

.alert-box-popup.alert-box-toast .alert-box-animate-success-icon .alert-box-success-line-tip {
  -webkit-animation: animate-toast-success-tip 0.75s;
  animation: animate-toast-success-tip 0.75s;
}

.alert-box-popup.alert-box-toast .alert-box-animate-success-icon .alert-box-success-line-long {
  -webkit-animation: animate-toast-success-long 0.75s;
  animation: animate-toast-success-long 0.75s;
}

@-webkit-keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0;
  }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: 0.5;
  }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
}
@keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0;
  }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: 0.5;
  }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1;
  }
}
@-webkit-keyframes hideSweetToast {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes hideSweetToast {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@-webkit-keyframes animate-toast-success-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes animate-toast-success-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@-webkit-keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
body.alert-box-shown:not(.alert-box-no-backdrop):not(.alert-box-toast-shown) {
  overflow-y: hidden;
}

body.alert-box-height-auto {
  height: auto !important;
}

body.alert-box-no-backdrop .alert-box-shown {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  background-color: transparent;
}

body.alert-box-no-backdrop .alert-box-shown > .alert-box-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-top {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-top-left,
body.alert-box-no-backdrop .alert-box-shown.alert-box-top-start {
  top: 0;
  left: 0;
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-top-end,
body.alert-box-no-backdrop .alert-box-shown.alert-box-top-right {
  top: 0;
  right: 0;
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-center-left,
body.alert-box-no-backdrop .alert-box-shown.alert-box-center-start {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-center-end,
body.alert-box-no-backdrop .alert-box-shown.alert-box-center-right {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-bottom-left,
body.alert-box-no-backdrop .alert-box-shown.alert-box-bottom-start {
  bottom: 0;
  left: 0;
}

body.alert-box-no-backdrop .alert-box-shown.alert-box-bottom-end,
body.alert-box-no-backdrop .alert-box-shown.alert-box-bottom-right {
  right: 0;
  bottom: 0;
}

.alert-box-container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  z-index: 1060;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.alert-box-container.alert-box-top {
  align-items: flex-start;
}

.alert-box-container.alert-box-top-left,
.alert-box-container.alert-box-top-start {
  align-items: flex-start;
  justify-content: flex-start;
}

.alert-box-container.alert-box-top-end,
.alert-box-container.alert-box-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}

.alert-box-container.alert-box-center {
  align-items: center;
}

.alert-box-container.alert-box-center-left,
.alert-box-container.alert-box-center-start {
  align-items: center;
  justify-content: flex-start;
}

.alert-box-container.alert-box-center-end,
.alert-box-container.alert-box-center-right {
  align-items: center;
  justify-content: flex-end;
}

.alert-box-container.alert-box-bottom {
  align-items: flex-end;
}

.alert-box-container.alert-box-bottom-left,
.alert-box-container.alert-box-bottom-start {
  align-items: flex-end;
  justify-content: flex-start;
}

.alert-box-container.alert-box-bottom-end,
.alert-box-container.alert-box-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.alert-box-container.alert-box-grow-fullscreen > .alert-box-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}

.alert-box-container.alert-box-grow-row > .alert-box-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.alert-box-container.alert-box-grow-column {
  flex: 1;
  flex-direction: column;
}

.alert-box-container.alert-box-grow-column.alert-box-bottom,
.alert-box-container.alert-box-grow-column.alert-box-center,
.alert-box-container.alert-box-grow-column.alert-box-top {
  align-items: center;
}

.alert-box-container.alert-box-grow-column.alert-box-bottom-left,
.alert-box-container.alert-box-grow-column.alert-box-bottom-start,
.alert-box-container.alert-box-grow-column.alert-box-center-left,
.alert-box-container.alert-box-grow-column.alert-box-center-start,
.alert-box-container.alert-box-grow-column.alert-box-top-left,
.alert-box-container.alert-box-grow-column.alert-box-top-start {
  align-items: flex-start;
}

.alert-box-container.alert-box-grow-column.alert-box-bottom-end,
.alert-box-container.alert-box-grow-column.alert-box-bottom-right,
.alert-box-container.alert-box-grow-column.alert-box-center-end,
.alert-box-container.alert-box-grow-column.alert-box-center-right,
.alert-box-container.alert-box-grow-column.alert-box-top-end,
.alert-box-container.alert-box-grow-column.alert-box-top-right {
  align-items: flex-end;
}

.alert-box-container.alert-box-grow-column > .alert-box-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.alert-box-container:not(.alert-box-top):not(.alert-box-top-start):not(.alert-box-top-end):not(.alert-box-top-left):not(.alert-box-top-right):not(.alert-box-center-start):not(.alert-box-center-end):not(.alert-box-center-left):not(.alert-box-center-right):not(.alert-box-bottom):not(.alert-box-bottom-start):not(.alert-box-bottom-end):not(.alert-box-bottom-left):not(.alert-box-bottom-right):not(.alert-box-grow-fullscreen) > .alert-box-modal {
  margin: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .alert-box-container .alert-box-modal {
    margin: 0 !important;
  }
}
.alert-box-container.alert-box-fade {
  transition: background-color 0.1s;
}

.alert-box-container.alert-box-shown {
  background-color: rgba(0, 0, 0, 0.4);
}

.alert-box-popup {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
}

.alert-box-popup:focus {
  outline: 0;
}

.alert-box-popup.alert-box-loading {
  overflow-y: hidden;
}

.alert-box-popup .alert-box-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert-box-popup .alert-box-title {
  display: block;
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.alert-box-popup .alert-box-actions {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1.25em auto 0;
  z-index: 1;
}

.alert-box-popup .alert-box-actions:not(.alert-box-loading) .alert-box-styled[disabled] {
  opacity: 0.4;
}

.alert-box-popup .alert-box-actions:not(.alert-box-loading) .alert-box-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.alert-box-popup .alert-box-actions:not(.alert-box-loading) .alert-box-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.alert-box-popup .alert-box-actions.alert-box-loading .alert-box-styled.alert-box-confirm {
  width: 2.5em;
  height: 2.5em;
  margin: 0.46875em;
  padding: 0;
  border: 0.25em solid transparent;
  border-radius: 100%;
  border-color: transparent;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  box-sizing: border-box;
  -webkit-animation: alert-box-rotate-loading 1.5s linear 0s infinite normal;
  animation: alert-box-rotate-loading 1.5s linear 0s infinite normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.alert-box-popup .alert-box-actions.alert-box-loading .alert-box-styled.alert-box-cancel {
  margin-right: 30px;
  margin-left: 30px;
}

.alert-box-popup .alert-box-actions.alert-box-loading :not(.alert-box-styled).alert-box-confirm::after {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border: 3px solid #999;
  border-radius: 50%;
  border-right-color: transparent;
  box-shadow: 1px 1px 1px #fff;
  content: "";
  -webkit-animation: alert-box-rotate-loading 1.5s linear 0s infinite normal;
  animation: alert-box-rotate-loading 1.5s linear 0s infinite normal;
}

.alert-box-popup .alert-box-styled {
  margin: 0.3125em;
  padding: 0.625em 2em;
  font-weight: 500;
  box-shadow: none;
}

.alert-box-popup .alert-box-styled:not([disabled]) {
  cursor: pointer;
}

.alert-box-popup .alert-box-styled.alert-box-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #3085d6;
  color: #fff;
  font-size: 1.0625em;
}

.alert-box-popup .alert-box-styled.alert-box-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 1.0625em;
}

.alert-box-popup .alert-box-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4);
}

.alert-box-popup .alert-box-styled::-moz-focus-inner {
  border: 0;
}

.alert-box-popup .alert-box-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}

.alert-box-popup .alert-box-image {
  max-width: 100%;
  margin: 1.25em auto;
}

.alert-box-popup .alert-box-close {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 0;
  background: 0 0;
  color: #ccc;
  font-family: inherit;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
  overflow: hidden;
}

.alert-box-popup .alert-box-close:hover {
  -webkit-transform: none;
  transform: none;
  color: #f27474;
}

.alert-box-popup > .alert-box-checkbox,
.alert-box-popup > .alert-box-file,
.alert-box-popup > .alert-box-input,
.alert-box-popup > .alert-box-radio,
.alert-box-popup > .alert-box-select,
.alert-box-popup > .alert-box-textarea {
  display: none;
}

.alert-box-popup .alert-box-content {
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 1.125em;
  font-weight: 300;
  line-height: normal;
  z-index: 1;
  word-wrap: break-word;
}

.alert-box-popup #alert-box-content {
  text-align: center;
}

.alert-box-popup .alert-box-checkbox,
.alert-box-popup .alert-box-file,
.alert-box-popup .alert-box-input,
.alert-box-popup .alert-box-radio,
.alert-box-popup .alert-box-select,
.alert-box-popup .alert-box-textarea {
  margin: 1em auto;
}

.alert-box-popup .alert-box-file,
.alert-box-popup .alert-box-input,
.alert-box-popup .alert-box-textarea {
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  font-size: 1.125em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
}

.alert-box-popup .alert-box-file.alert-box-inputerror,
.alert-box-popup .alert-box-input.alert-box-inputerror,
.alert-box-popup .alert-box-textarea.alert-box-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}

.alert-box-popup .alert-box-file:focus,
.alert-box-popup .alert-box-input:focus,
.alert-box-popup .alert-box-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: 0 0 3px #c4e6f5;
}

.alert-box-popup .alert-box-file::-webkit-input-placeholder,
.alert-box-popup .alert-box-input::-webkit-input-placeholder,
.alert-box-popup .alert-box-textarea::-webkit-input-placeholder {
  color: #ccc;
}

.alert-box-popup .alert-box-file:-ms-input-placeholder,
.alert-box-popup .alert-box-input:-ms-input-placeholder,
.alert-box-popup .alert-box-textarea:-ms-input-placeholder {
  color: #ccc;
}

.alert-box-popup .alert-box-file::-ms-input-placeholder,
.alert-box-popup .alert-box-input::-ms-input-placeholder,
.alert-box-popup .alert-box-textarea::-ms-input-placeholder {
  color: #ccc;
}

.alert-box-popup .alert-box-file::placeholder,
.alert-box-popup .alert-box-input::placeholder,
.alert-box-popup .alert-box-textarea::placeholder {
  color: #ccc;
}

.alert-box-popup .alert-box-range input {
  width: 80%;
}

.alert-box-popup .alert-box-range output {
  width: 20%;
  font-weight: 600;
  text-align: center;
}

.alert-box-popup .alert-box-range input,
.alert-box-popup .alert-box-range output {
  height: 2.625em;
  margin: 1em auto;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.alert-box-popup .alert-box-input {
  height: 2.625em;
  padding: 0 0.75em;
}

.alert-box-popup .alert-box-input[type=number] {
  max-width: 10em;
}

.alert-box-popup .alert-box-file {
  font-size: 1.125em;
}

.alert-box-popup .alert-box-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.alert-box-popup .alert-box-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  color: #545454;
  font-size: 1.125em;
}

.alert-box-popup .alert-box-checkbox,
.alert-box-popup .alert-box-radio {
  align-items: center;
  justify-content: center;
}

.alert-box-popup .alert-box-checkbox label,
.alert-box-popup .alert-box-radio label {
  margin: 0 0.6em;
  font-size: 1.125em;
}

.alert-box-popup .alert-box-checkbox input,
.alert-box-popup .alert-box-radio input {
  margin: 0 0.4em;
}

.alert-box-popup .alert-box-validation-message {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden;
}

.alert-box-popup .alert-box-validation-message::before {
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
  content: "!";
  zoom: normal;
}

@supports (-ms-accelerator: true) {
  .alert-box-range input {
    width: 100% !important;
  }
  .alert-box-range output {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .alert-box-range input {
    width: 100% !important;
  }
  .alert-box-range output {
    display: none;
  }
}
@-moz-document url-prefix() {
  .alert-box-close:focus {
    outline: 2px solid rgba(50, 100, 150, 0.4);
  }
}
.alert-box-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: normal;
}

.alert-box-icon-text {
  font-size: 3.75em;
}

.alert-box-icon.alert-box-error {
  background-color: #f27474;
}

.alert-box-icon.alert-box-error .alert-box-x-mark {
  position: relative;
  flex-grow: 1;
}

.alert-box-icon.alert-box-error [class^=alert-box-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #fff;
}

.alert-box-icon.alert-box-error [class^=alert-box-x-mark-line][class$=left] {
  left: 1.0625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.alert-box-icon.alert-box-error [class^=alert-box-x-mark-line][class$=right] {
  right: 1em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.alert-box-icon.alert-box-warning {
  background-color: #f8bb86;
  color: #fff;
}

.alert-box-icon.alert-box-info {
  background-color: #3fc3ee;
  color: #fff;
}

.alert-box-icon.alert-box-question {
  background-color: #87adbd;
  color: #fff;
}

.alert-box-icon.alert-box-success {
  background-color: #a5dc86;
}

.alert-box-icon.alert-box-success [class^=alert-box-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}

.alert-box-icon.alert-box-success [class^=alert-box-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 3.75em 3.75em;
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}

.alert-box-icon.alert-box-success [class^=alert-box-success-circular-line][class$=right] {
  top: -0.6875em;
  left: 1.875em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 3.75em;
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}

.alert-box-icon.alert-box-success .alert-box-success-ring {
  position: absolute;
  top: -0.25em;
  left: -0.25em;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  z-index: 2;
  box-sizing: content-box;
}

.alert-box-icon.alert-box-success .alert-box-success-fix {
  position: absolute;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 1;
}

.alert-box-icon.alert-box-success [class^=alert-box-success-line] {
  display: block;
  position: absolute;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #fff;
  z-index: 2;
}

.alert-box-icon.alert-box-success [class^=alert-box-success-line][class$=tip] {
  top: 2.875em;
  left: 0.875em;
  width: 1.5625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.alert-box-icon.alert-box-success [class^=alert-box-success-line][class$=long] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.alert-box-progresssteps {
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  font-weight: 600;
}

.alert-box-progresssteps li {
  display: inline-block;
  position: relative;
}

.alert-box-progresssteps .alert-box-progresscircle {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #3085d6;
  color: #fff;
  line-height: 2em;
  text-align: center;
  z-index: 20;
}

.alert-box-progresssteps .alert-box-progresscircle:first-child {
  margin-left: 0;
}

.alert-box-progresssteps .alert-box-progresscircle:last-child {
  margin-right: 0;
}

.alert-box-progresssteps .alert-box-progresscircle.alert-box-activeprogressstep {
  background: #3085d6;
}

.alert-box-progresssteps .alert-box-progresscircle.alert-box-activeprogressstep ~ .alert-box-progresscircle {
  background: #add8e6;
}

.alert-box-progresssteps .alert-box-progresscircle.alert-box-activeprogressstep ~ .alert-box-progressline {
  background: #add8e6;
}

.alert-box-progresssteps .alert-box-progressline {
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #3085d6;
  z-index: 10;
}

[class^=alert-box] {
  -webkit-tap-highlight-color: transparent;
}

.alert-box-show {
  -webkit-animation: alert-box-show 0.3s;
  animation: alert-box-show 0.3s;
}

.alert-box-show.alert-box-noanimation {
  -webkit-animation: none;
  animation: none;
}

.alert-box-hide {
  -webkit-animation: alert-box-hide 0.15s forwards;
  animation: alert-box-hide 0.15s forwards;
}

.alert-box-hide.alert-box-noanimation {
  -webkit-animation: none;
  animation: none;
}

[dir=rtl] .alert-box-close {
  right: auto;
  left: 0;
}

.alert-box-animate-success-icon .alert-box-success-line-tip {
  -webkit-animation: alert-box-animate-success-line-tip 0.75s;
  animation: alert-box-animate-success-line-tip 0.75s;
}

.alert-box-animate-success-icon .alert-box-success-line-long {
  -webkit-animation: alert-box-animate-success-line-long 0.75s;
  animation: alert-box-animate-success-line-long 0.75s;
}

.alert-box-animate-success-icon .alert-box-success-circular-line-right {
  -webkit-animation: alert-box-rotate-success-circular-line 4.25s ease-in;
  animation: alert-box-rotate-success-circular-line 4.25s ease-in;
}

.alert-box-animate-error-icon {
  -webkit-animation: alert-box-animate-error-icon 0.5s;
  animation: alert-box-animate-error-icon 0.5s;
}

.alert-box-animate-error-icon .alert-box-x-mark {
  -webkit-animation: alert-box-animate-error-x-mark 0.5s;
  animation: alert-box-animate-error-x-mark 0.5s;
}

@-webkit-keyframes alert-box-rotate-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes alert-box-rotate-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media print {
  body.alert-box-shown:not(.alert-box-no-backdrop):not(.alert-box-toast-shown) {
    overflow-y: scroll !important;
  }
  body.alert-box-shown:not(.alert-box-no-backdrop):not(.alert-box-toast-shown) > [aria-hidden=true] {
    display: none;
  }
  body.alert-box-shown:not(.alert-box-no-backdrop):not(.alert-box-toast-shown) .alert-box-container {
    position: initial !important;
  }
}
body.ready .landing-advertise {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.landing-advertise {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: 300ms ease;
  perspective: 500px;
}
.landing-advertise.ad-close {
  opacity: 0;
  visibility: hidden;
}
.landing-advertise.ad-close .ad-frame {
  opacity: 0;
  transition: 300ms ease;
  transform: translate(0, -50px);
}
.landing-advertise .btn-close,
.landing-advertise .btn-close-bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 1.2rem;
  height: 1.2rem;
  border: 0.7rem solid transparent;
  border-radius: 0 0.5rem 0 50%;
  box-sizing: content-box;
}
.landing-advertise .btn-close-bg {
  background: #111;
}
.landing-advertise .btn-close {
  cursor: pointer;
  transition: transform 200ms ease;
}
.landing-advertise .btn-close:before, .landing-advertise .btn-close:after {
  content: "";
  border: 1px solid white;
  border-width: 1px 0 1px 0;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  font-size: 0;
}
.landing-advertise .btn-close:before {
  transform: rotate(45deg);
}
.landing-advertise .btn-close:after {
  transform: rotate(-45deg);
}
.landing-advertise .btn-close:hover {
  transform: scale(1.2);
}
.landing-advertise .btn-close:active {
  transform: scale(1.05);
}
.landing-advertise .ad-frame {
  position: relative;
  background-color: #111;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  border-radius: 0.5rem;
}
.landing-advertise .ad-image {
  min-width: 100px;
  min-height: 100px;
  border-radius: 0.2rem;
  overflow: hidden;
  font-size: 0;
  max-width: calc(100vw - 50px);
  max-height: calc(100vh - 100px);
  overflow: auto;
}
.landing-advertise .ad-image img {
  max-width: 100%;
}
.landing-advertise .ad-countdown {
  color: white;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.landing-advertise .ad-frame {
  background-color: transparent !important;
}
.landing-advertise .btn-close-bg {
  display: none;
}
.landing-advertise .btn-close {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}