body.ready .landing-advertise{
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.landing-advertise {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: 300ms ease;
  perspective: 500px;
  &.ad-close {
    opacity: 0;
    visibility: hidden;
    .ad-frame {
      opacity: 0;
      transition: 300ms ease;
      transform: translate(0, -50px);
    }
  }

  .btn-close,
  .btn-close-bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.7rem solid transparent;
    border-radius: 0 0.5rem 0 50%;
    box-sizing: content-box;
  }

  .btn-close-bg {
    background: #111;
  }

  .btn-close {
    cursor: pointer;
    transition: transform 200ms ease;
    &:before,
    &:after {
      content: "";
      border: 1px solid white;
      border-width: 1px 0 1px 0;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 0;
      font-size: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      transform: scale(1.2);
    }
    &:active {
      transform: scale(1.05);
    }
  }

  .ad-frame {
    position: relative;
    background-color: #111;
    padding: 0.7rem 0.7rem 0.7rem 0.7rem;
    border-radius: 0.5rem;
  }
  .ad-image {
    min-width: 100px;
    min-height: 100px;
    border-radius: 0.2rem;
    overflow: hidden;
    font-size: 0;
    max-width: calc(100vw - 50px);
    max-height: calc(100vh - 100px);
    overflow: auto;
    img{
      max-width: 100%;
    }
  }
  .ad-countdown {
    color: white;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
}

.landing-advertise {
  .ad-frame {
    background-color: transparent !important;
  }

  .btn-close-bg {
    display: none;
  }

  .btn-close {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
